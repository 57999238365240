import styled, { css } from "styled-components/macro"

export const HeaderContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: white;
    ${theme.shadows.header}
  `}
`

export const Header = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  gap: 25px;

  ${({ theme }) => theme.medias.portrait} {
    flex-wrap: wrap;
    padding: 20px;
    gap: 1.5rem;
    justify-content: center;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 160px;

  img {
    max-width: 100%;
    max-height: 70px;
  }

  ${({ theme }) => theme.medias.portrait} {
    order: 1;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  h1 {
    margin: 0;

    ${({ theme }) => theme.medias.portrait} {
      text-align: center;
      font-size: 1.5rem !important;
    }
  }

  ${({ theme }) => theme.medias.portrait} {
    order: 3;
  }
`

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  
  p {
    font-size: 2rem;
    font-weight: bold;
  }
  
  ${({ theme }) => theme.medias.portrait} {
    text-align: center;

  
  p {
    font-size: 1.6rem !important;
    font-weight: bold;
  }
  }
`
