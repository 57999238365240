import { Component } from "utilities/types"
import React, { useState } from "react"
import { ClapnclickVideo } from "../../../../graphql/models/generated"
import {
  AddClapnclickUserOrUserInputDocument,
  AddClapnclickUserOrUserInputMutation,
} from "../../../../graphql/mutations/generated/AddClapnclickUserOrUserInput"
import {
  GetModuleByCompanySlugAndModuleIdQuery,
} from "../../../../graphql/queries/generated/GetModuleByCompanySlugAndModuleId"
import * as Styles from "./ResultPage.styles"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { TextInput, Title } from "features/components"
import { useMutation } from "@apollo/client"

export const ResultPage: Component<ResultPopUpProps> = ({ video, module, formData }) => {
  const history = useHistory()
  const { register, handleSubmit } = useForm()
  const [ isFormSubmitted, setIsFormSubmitted ] = useState(false)
  const [ addUserOrUserInputMutation ] = useMutation<AddClapnclickUserOrUserInputMutation>(
    AddClapnclickUserOrUserInputDocument,
  )

  const [ isAlreadyAnswered, setIsAlreadyAnswered ] = useState(false)

  const onSubmit = (data: Record<string, unknown>) => {
    const { firstName, lastName, email, ...customFields  } = data

    addUserOrUserInputMutation({
      variables: {
        clapnclickUserCustomFields: Object.entries(customFields ?? []).map(
          ([ customFieldId, customFieldValue ]) => ({
            value: customFieldValue,
            customFieldId: parseInt(customFieldId),
          })),
        clapnclickUserVideoChoices: [ {
          videoId: video[0].id,
          videoChoice: parseInt(formData[video[0].id] as string),
        } ],
        email: email,
        firstName: firstName,
        lastName: lastName,
        moduleId: module?.id,
      },
    }).catch(e => {
      if (e.graphQLErrors) {
        setIsAlreadyAnswered(true)
      }
    }).then(() => {
      setIsFormSubmitted(true)
    })
  }

  const moduleData = module

  return (
    <>
      {module && video && (
        <>
          <Styles.Container>
            <Styles.StyledSecondaryButton onClick={history.goBack}>Retour</Styles.StyledSecondaryButton>
            <Styles.GridContainer>
              <div>
                <Styles.Title
                  color="black"
                  asHeading={"h2"}
                  size={"large"}
                >Je participe au concours</Styles.Title>
                <Styles.Caption>Les champs marqués d'un * sont obligatoires.</Styles.Caption>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Styles.FieldSet>
                    <TextInput isRequired label="Prénom" type="text" placeholder="John" {...register("firstName")} />
                    <TextInput isRequired label="Nom" type="text" placeholder="DOE" {...register("lastName")} />
                  </Styles.FieldSet>
                  <TextInput
                    isRequired
                    label="email"
                    type="text"
                    placeholder="johndoe@gmail.com"
                    {...register("email", { pattern: /^\S+@\S+$/i })}
                  />
                  {moduleData?.customFields?.map((customField) => {
                    if (customField) {
                      if (customField.fieldType === "text") {
                        return (
                          <TextInput
                            isRequired={customField.required}
                            label={customField.label}
                            key={customField.id}
                            type="text"
                            placeholder=""
                            {...register(`${customField.id}`)} />
                        )
                      }
                      else if (customField.fieldType === "select") {
                        const selectList = JSON.parse(customField.value)
                        return (
                          <Styles.SelectContainer
                            key={customField.id}
                          >
                            <Styles.SelectLabel>
                              {customField.label}
                              {customField.required ? " *" : ""}
                            </Styles.SelectLabel>
                            <Styles.SelectInput
                              {...register(
                                `${customField.id}`, { required: customField.required },
                              )}
                            >
                              <option value="" disabled selected hidden>Choisissez une option...</option>
                              {selectList.map((option: string) => (
                                <option key={option} value={option}>{option}</option>
                              ))}
                            </Styles.SelectInput>
                          </Styles.SelectContainer>
                        )
                      }
                    }
                    return null
                  })}
                  <Styles.Caption>
                    En cliquant sur "Valider ma participation" j'accepte le règlement du concours (
                    <a target="_blank" href={module.settlement} rel="noreferrer">Téléchargeable
                      ici en PDF</a>)
                  </Styles.Caption>
                  <Styles.StyledPrimaryButton
                    type="submit">Valider ma participation
                  </Styles.StyledPrimaryButton>
                </form>
              </div>
              <div>
                <Styles.TextContainer
                  dangerouslySetInnerHTML={{ __html: video[0].resultText }}
                />
              </div>
            </Styles.GridContainer>
          </Styles.Container>
          {isFormSubmitted ? (
            <Styles.Modal>
              {!isAlreadyAnswered ? (
                <Styles.ModalContainer>
                  <Styles.StyledIcon color={"#2ecc71"} name={"check_circle_outline"}/>
                  <Styles.ThankYouText
                    asHeading={"h2"}
                    renderHtml
                  >
                    {video[0].thankYouText}
                  </Styles.ThankYouText>
                  <Styles.HomeButton
                    type="submit"
                    onClick={history.goBack}
                  >
                    Retour à la page d'accueil
                  </Styles.HomeButton>
                </Styles.ModalContainer>
              ) : (
                <Styles.ModalContainer>
                  <Styles.StyledIcon color={"#e74c3c"} name={"highlight_off"}/>
                  <Title asHeading={"h2"}>Vous avez déjà participé.</Title>
                  <Styles.HomeButton
                    type="submit"
                    onClick={history.goBack}
                  >
                    Retour à la page d'accueil
                  </Styles.HomeButton>
                </Styles.ModalContainer>
              )}
            </Styles.Modal>
          ) : ""}
        </>
      )}
    </>
  )
}

export interface ResultPopUpProps {
  module: GetModuleByCompanySlugAndModuleIdQuery["getClapnclickModuleById"],
  video: ClapnclickVideo[],
  formData: Record<string | number, unknown> | [],
}
